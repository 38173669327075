import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SeO from "../components/seo"
import YoutubeEmbed from "../components/YoutubeEmbed"

import { css } from '@emotion/react'

const WebinarPage = () => (
    <Layout>
        <SeO title="Webinar Video-Lab" />
        <div css={css`
        margin: 40px 0 50px;
        `} className="constrained">
            <h1>Webinar : pourquoi et comment produire vos propres vidéos</h1>
            <p className="content-intro">Ce webinar gratuit vous présente notre formation à la vidéo - spécialement adaptée aux PME/TPEs  et entrepreneurs. Devenir indépendant dans votre production vidéo est LE meilleur investissement pour votre marketing digital que vous pourrez faire cette année, alors suivez le guide !</p>
        </div>

        {false && <section>
            <h2>Avec la formation, vous allez savoir parfaitement :</h2>
            
            <div className="three-cols">
                <div><h3>Stratégiser</h3>Identifiez les besoins vidéo de votre entreprise, en partant des besoins de vos prospects et clients, afin de lister et prioriser les contenus que vous devrez produire.</div>
                <div><h3>Filmer + monter</h3>Produisez vos contenus vidéo simplement et rapidement, sans agence multimédia, en investissant dans vos compétences internes et votre digitalisation à long terme.</div>
                <div><h3>Diffuser</h3>Atteignez de nouveaux clients ou fidélisez votre base existante: animez vos réseaux sociaux, sites webs, youtube, newsletters, et publicités en ligne...</div>
            </div>
        </section>}

        <section className={`card`} css={demoSection}>
            <h2><span className="stabilo">Présentation et démo</span> du programme vidéo</h2>
            <div style={{"marginTop": "10px", "marginBottom": "40px"}}>
                <YoutubeEmbed youtubeId={`lKXxBEV5kpE`} />
            </div>
            <div className="two-cols">
                <div>
                    <h3>Découvrir en 45 minutes :</h3>
                    <ul>
                        <li>Pourquoi la vidéo va <b>booster votre activité</b></li>
                        <li>Comment se présente le <b>programme et les modules</b></li>
                        <li>Comment obtenir un <b>retour sur investissement</b></li>
                        <li>Pourquoi c'est <b>adapté aux PMEs + indépendants</b></li>
                        <li>Des exemples de <b>contenus vidéo à produire</b></li>
                    </ul>
                </div>

                <div>
                    <Link to={`/modules`} className="button--primary">Voir les modules</Link>
                    <Link to={`/inscription`} className="button--primary pink">S'inscrire au programme</Link>
                </div>
            </div>
        </section>

        <section>
            <h2>Trop de PMEs/TPEs et indépendants ignorent encore le <span className="stabilo">potentiel de la vidéo</span> pour développer leur activité</h2>
            <p>Alors que la vidéo est aujourd'hui le média pax excellence sur les réseaux sociaux (Instagram TV, Facebook Stories, TikTok) et dans la vie de tous les jours (YouTube, Netflix...), trop d'entreprises sont encore à la traîne pour l'utiliser dans la promotion de leur activité.</p>
            <div className="two-cols">
                <div>
                    <h3>L'erreur classique des PMEs</h3>
                    <ul>
                        <li>Ne pas savoir ce que la vidéo peut apporter à leur activité</li>
                        <li>Ne pas avoir ou développer des ressources vidéo en interne</li>
                        <li>Penser ne pas avoir le temps pour ces activités de promotion</li>
                        <li>Ne pas avoir la confiance en son potentiel d'apprentissage</li>
                        <li>Ne pas savoir quoi filmer, avoir l'angoisse de la feuille blanche</li>
                        <li>Ne pas savoir comment monter / éditer une vidéo convainquante</li>
                        <li>Ne pas savoir quoi publier sur les réseaux</li>
                        <li>Devoir payer et gérer une agence multimédia</li>
                        <li>Créer du contenu vidéo peu adapté aux plateformes et réseaux sociaux</li>
                    </ul>
                </div>

                <div>
                    <h3>Opportunités vidéos pour l'entreprise</h3>
                    <ul>
                        <li>Développer sa visibilité sur internet avec un format moderne</li>
                        <li>Mieux répondre aux questions de ses éventuels clients</li>
                        <li>Mieux présenter ses offres de produits et services</li>
                        <li>Humaniser l'entreprise et établir une relation de confiance avec prospects et clients</li>
                        <li>Créer une communauté par un contenu vidéo régulier utile</li>
                        <li>Positionner l'entreprise dans la modernité, devant ses concurrents</li>
                        <li>Donner aux équipes les moyens de communiquer mieux</li>
                        <li>Créer un dialogue simple et rapide avec sa nouvelle communauté</li>
                    </ul>
                </div>
            </div>
        </section>
    </Layout>
)

export default WebinarPage

const demoSection = css`
    // margin: 0 -30px;
    padding: 40px 30px !important;

    li {
        font-size: 1.6rem;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 40px;
    }

    h3 {
        margin-top: 0;
    }
`